.fiyat-liste-area {
  padding: 50px;
  & > p {
    padding-bottom: 20px;
    border-bottom: 3px solid black;
    font-size: 40px;
    text-align: center;
  }
}
.fiyat-liste-cell {
  height: 100%;
  & > img {
    width: 200px;
    height: 100px;
  }
  border-bottom: 3px solid black;
  padding: 10px 0;
}
.fiyat-liste-link {
  background-color: #ff5e15;
  border-radius: 50px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 5px 10px;
  gap: 10px;
  & > a {
    color: white;
    text-decoration: none;
  }
  & > img {
    width: 20px;
  }
}
@media screen and (max-width: 750px) {
  .fiyat-liste-cell {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }
  .fiyat-liste-cell {
    height: unset;
  }
  .fiyat-liste-area {
    padding: 50px 20px;
    & > p {
      font-size: 20px;
    }
  }
}
