footer {
  background-color: #000000;
  padding: 50px;
  margin-top: 50px;
  color: white;
}
.footer-top-section {
  padding-bottom: 30px;
  border-bottom: 2px solid #ff5e15;
}
.footer-section {
  color: white;
  gap: 10px;
  line-height: 1.7;
  & > span {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 16px;
    line-height: 19px;
    border-bottom: 2px solid #ff5e15;
  }
}
.footer-bottom-section {
  margin: 20px 0;
}
.footer-social {
  gap: 10px;
}
@media screen and (max-width: 780px) {
  .footer-top-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 50px;
  }
}
@media screen and (max-width: 650px) {
  .footer-social > img {
    max-width: 30px;
  }
}
