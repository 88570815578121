.imkanlar-div {
  margin: 50px 0;
  text-align: center;
  & > span {
    color: #ff5e15;
    font-size: 40px;
    line-height: 25px;
    margin-bottom: 50px;
  }
}
.imkanlar-card-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 50px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 30px 0;
}
.imkanlar-card {
  border-radius: 10px;
  max-width: 300px;
  position: relative;
  & > img {
    max-width: 100%;
    border-radius: 10px;
  }
}
.imkanlar-desc {
  position: absolute;
  bottom: 0;
  background-color: #09162a;
  border-radius: 10px;
  padding: 10px;
  & > p {
    font-size: 15px;
    line-height: 26px;
    color: white;
    text-align: start;
    & > span {
      font-size: 24px;
      line-height: 29px;
    }
  }
}
//---------------------------
.why-me-area {
  position: relative;
  padding: 50px;
  background-color: #09162a;
  margin-top: 100px;
  & > img {
    position: absolute;
    max-width: 30%;
    top: -40px;
    left: 10%;
  }
}
.why-me-desc-area {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  & > p {
    color: #808080;
  }
}
.why-me-title {
  font-size: 20px;
  line-height: 25px;
  color: #ff5e15;
}
.why-me-sub-title {
  font-size: 32px;
  line-height: 38px;
  color: white;
}
.why-me-sub-desc {
  margin: 0 auto;
  margin-top: 50px;
  color: white;
  font-size: 17px;
  line-height: 30px;
}
.why-me-sub-desc-title {
  font-size: 20px;
  line-height: 24px;
}
.splideCell {
  margin: 0 10px;
  & > img {
    max-width: 100%;
  }
}
.own-products {
  background-color: #09162a;
  padding: 50px;
}
#splide01 {
  border: 4px solid #ff5e15;
  border-radius: 16px;
  padding: 20px;
  margin: 20px 0;
}
@media screen and (max-width: 768px) {
  .why-me-area > img {
    max-width: 70% !important;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .why-me-desc-area {
    margin: 0 auto;
    width: 70%;
    margin-top: 95%;
    margin-bottom: 40px;
  }
  .why-me-area {
    padding: 10px;
  }
  .imkanlar-div > span {
    font-size: 30px;
  }
  .own-products {
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .imkanlar-card-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .why-me-area > img {
    max-width: 35%;
  }
}
