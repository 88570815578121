.bayiliklerimiz-area {
  text-align: center;
  padding: 50px;
  border-bottom: 5px solid #ff5e15;
  border-radius: 50px;
  & > p {
    font-size: 40px;
    line-height: 58px;
  }
}
.bayiliklerimiz-content {
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 30px 0;
  & > div {
    height: 150px;
    & > img {
      width: 70%;
      -o-object-fit: scale-down;
      object-fit: scale-down;
    }
  }
}
.own-product {
  padding: 50px;
  & > p {
    font-size: 40px;
    line-height: 58px;
  }
}
.own-product-24 {
  gap: 50px;
  margin: 20px;
  & > img {
    width: 40%;
  }
}
.own-product-cable {
  gap: 30px;
  margin-top: 20px;
}
.own-product-cell {
  width: 30%;
  text-align: center;
  gap: 20px;
  & > img {
    max-width: 80%;
  }
  border: 3px solid #ff5e15;
  border-radius: 20px;
  padding: 10px;
}
@media screen and (max-width: 650px) {
  .bayiliklerimiz-content {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
  .bayiliklerimiz-area {
    padding: 50px 20px;
    & > p {
      font-size: 20px;
    }
  }
  .own-product {
    padding: 50px 20px;
    & > p {
      font-size: 20px;
    }
  }
  .own-product-24 {
    gap: 20px;
  }
  .own-product-cable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .own-product-cell {
    width: 90%;
  }
}
