.home-communication-area {
  margin-top: 50px;
  gap: 120px;
  & > img {
    max-width: 50%;
  }
}
.homeCommunication {
  background-color: #09162a;
  padding: 80px;
  width: 40%;
  & > textarea {
    margin-top: 15px;
    background-color: transparent;
    color: white;
    width: 100%;
    height: 150px;
    padding: 10px;
    &::-webkit-input-placeholder {
      color: #fff;
    }
    &::-moz-placeholder {
      color: #fff;
    }
    &:-ms-input-placeholder {
      color: #fff;
    }
    &::-ms-input-placeholder {
      color: #fff;
    }
    &::placeholder {
      color: #fff;
    }
    &:focus-visible {
      border: unset;
    }
  }
}
.home-communication-title {
  font-size: 32px;
  line-height: 38px;
  color: white;
  & > span {
    font-size: 20px;
    line-height: 25px;
    color: #ff5e15;
  }
}
.home-communication-input-div {
  margin-top: 50px;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 15px;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  & > input {
    background-color: transparent;
    width: 100%;
    border: unset;
    border-bottom: 1px solid #fff;
    padding: 10px 0;
    color: #fff;
    outline: none;
    &::-webkit-input-placeholder {
      color: #fff;
    }
    &::-moz-placeholder {
      color: #fff;
    }
    &:-ms-input-placeholder {
      color: #fff;
    }
    &::-ms-input-placeholder {
      color: #fff;
    }
    &::placeholder {
      color: #fff;
    }
    &:focus-visible {
      border: unset;
      border-bottom: 1px solid #fff;
    }
  }
}
.home-form-submit {
  width: 50%;
  margin-left: auto;
  margin-top: 10px;
  color: white;
  padding: 10px 30px;
  background-color: #ff5e15;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.home-form-submit:hover {
  background-color: #df5414;
}
@media screen and (max-width: 965px) {
  .home-communication-area {
    & > img {
      max-width: 25%;
    }
    margin: 30px 0;
  }
  .homeCommunication {
    padding: 30px;
    width: 60%;
  }
}
@media screen and (max-width: 650px) {
  .home-communication-area {
    & > img {
      max-width: 50%;
    }
  }
  .home-communication-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 30px;
  }
  .homeCommunication {
    padding: 20px;
    width: 90%;
  }
  .home-communication-title {
    font-size: 20px;
  }
}
