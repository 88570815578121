.bm-burger-button {
  position: fixed;
  width: 30px;
  height: 25px;
  right: 36px;
  top: 27px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  height: 10% !important;
}

/* Color/shape of burger icon bars on hover*/

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #eee;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/* Individual item */
.bm-item {
  display: inline-block;
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  color: #4d4d4d;
}

.bm-item:hover {
  color: #1f3882;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (min-width: 550px) {
  .bm-burger-button {
    display: none;
  }
}
