.header-area {
  background-color: black;
}
.header-logo-div {
  gap: 10px;
  cursor: pointer;
  & > img {
    max-width: 60px;
  }
  & > p {
    color: white;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    margin: 0;
  }
}
.top-header {
  padding: 30px;
}
.header-location-div {
  gap: 10px;
  cursor: pointer;
  text-decoration: none;
}
.header-title {
  font-size: 16px;
  line-height: 23px;
  color: white;
  margin: 0;
}
.header-desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #808080;
  margin: 0;
}
.alt-header {
  gap: 40px;
  background-color: #ff5e15;
  border-radius: 100px;
}
.header-home-icon {
  width: 25px;
}
.alt-header-button {
  color: white;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 58px;
}
.alt-header-button:hover {
  color: black;
}
@media screen and (max-width: 930px) {
  .header-location-div {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .alt-header {
    display: none;
  }
  .top-header {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    padding: 10px;
  }
  .header-area {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .header-logo-div {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    & > p {
      font-size: 18px;
    }
  }
}
