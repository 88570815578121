@import "react-slideshow-image/dist/styles.css";

.each-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.slide-img {
  width: 100%;
  max-height: 600px;
  -o-object-fit: fill;
  object-fit: fill;
}
.react-slideshow-fadezoom-wrapper:hover .default-nav[data-type="next"] {
  opacity: 1;
  right: 50px;
}
.react-slideshow-fadezoom-wrapper:hover .default-nav[data-type="prev"] {
  opacity: 1;
  left: 50px;
}
.slider-caption {
  text-align: center;
  position: absolute;
  width: 100%;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}

.slider-caption h1 {
  text-shadow: 1px 1px #777;
  font-size: 48px;
  padding-bottom: 10px;
  font-weight: 500;
  color: #f78e21;
  text-transform: uppercase;
}

.slider-caption p {
  font-size: 18px;
  font-weight: 300;
  color: #fff;
  line-height: 15px;
}

.slider-caption a {
  margin-top: 20px;
  text-decoration: none;
  display: inline-block;
  background-color: #f78e21;
  width: 200px;
  height: 40px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}
.react-slideshow-container .default-nav {
  border: 1px solid #fff !important;
  border-radius: unset !important;
  background: rgba($color: #555, $alpha: 0.5);
  padding: 5px;
  margin: 0 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.react-slideshow-container .default-nav:is(:hover, :focus) {
  background: rgba($color: #ff5e15, $alpha: 0.5);
}
.home-slider-youtube {
  aspect-ratio: 7/3;
}
@media screen and (max-width: 768px) {
  .home-slider-youtube {
    aspect-ratio: 7/5;
  }
}
