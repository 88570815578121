.iletisim-title {
  text-align: center;
  margin: 50px 50px 0 50px;
  & > p {
    padding-bottom: 20px;
    font-size: 40px;
  }
}
.iletisim-page-table {
  padding: 0 50px;
  margin: 0 auto;
  border-collapse: collapse;
}
tr {
  border-bottom: 5px solid #ff5e15;
  border-radius: 16px;
}
.iletisim-social {
  gap: 20px;
  margin: 20px;
  & > img {
    cursor: pointer;
  }
}
iframe {
  border: none;
  min-height: 250px;
  width: 100%;
}
@media screen and (max-width: 665px) {
  .iletisim-page-table-div {
    overflow-x: scroll;
    width: 100%;
    padding: 10px;
  }
  .iletisim-title > p {
    font-size: 20px;
  }
  th,
  tr {
    padding: 0 45px;
    font-size: 0.8rem;
  }
}
