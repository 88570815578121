@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-family: 'Oswald', sans-serif;
}

a {
  cursor: pointer;
}

img {
  -o-object-fit: contain;
  object-fit: contain;
}

.full-w {
  width: 100%;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-grid {
  display: -ms-grid;
  display: grid;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.object-contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.flex-d-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.justify-s-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-s-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.text-dec-none {
  text-decoration: none;
}

.col-w {
  color: white;
}